import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';
import PlusIcon from 'components/icons/PlusIcon';
import { Link } from 'react-router-dom';

const Button = styled(Fab)`
  position: fixed;
  left: 24px;
  bottom: 24px;
`;

type Props = {
  to: string;
};

const FloatingButton = ({ to }: Props) => (
  <Link to={to} style={{ textDecoration: 'none' }}>
    <Button size="medium" color="primary">
      <PlusIcon stroke="white" />
    </Button>
  </Link>
);

export default FloatingButton;
