import React from 'react';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

const StyledCell = styled(TableCell)`
  // padding-left: ${(props) => (props.align === 'left' ? '0px' : '16px')};
  // padding-right: ${(props) => (props.align === 'right' ? '0px' : '16px')};
`;

type Props = { data: any[] };

// @TODO ------
// 1. ADD CORRECT STYLING

const Head = ({ data }: Props) => (
  <TableHead>
    <TableRow>
      {data.map((data, index) => (
        <StyledCell key={index} align={data.align}>
          {data.name}
        </StyledCell>
      ))}
    </TableRow>
  </TableHead>
);

export default Head;
