import React from 'react';

import useStore from 'store/useStore';

type Props = {
  roles: string[];
  children: JSX.Element;
};

const AllowedRole = ({ children, roles }: Props) => {
  const user = useStore((state) => state.user);

  const isRoleIncluded = roles?.includes(user.role) || false;

  // If roles have access - Render children
  if (isRoleIncluded) {
    return children;
  }

  return null;
};

export default AllowedRole;
