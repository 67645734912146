import React from 'react';

import { styled } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  buttonText: string;
  loading: boolean;
  size?: 'small' | 'medium' | 'large';
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: 'button' | 'submit' | 'reset' | undefined;
  handleClick?: () => void;
};

// @TODO - FIX THE CSS
const StyledLoadingbutton = styled(LoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  width: '100%',

  '&:hover': {
    boxShadow: 'none'
  }
}));

const ButtonLoading = ({
  buttonText,
  loading,
  size = 'small',
  startIcon,
  endIcon,
  type,
  handleClick,
  ...rest
}: Props) => (
  <StyledLoadingbutton
    size={size}
    onClick={handleClick}
    startIcon={startIcon}
    endIcon={endIcon}
    loading={loading}
    variant="contained"
    disableRipple
    type={type}
    {...rest}
  >
    {buttonText}
  </StyledLoadingbutton>
);

export default ButtonLoading;
