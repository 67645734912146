import React from 'react';
import { styled } from '@mui/material/styles';
import NavigationItem from 'components/Navigation/DesktopNavigation/NavigationItem';
import { NavigationLink } from 'components/Navigation';
import SettingsIcon from 'components/icons/SettingsIcon';

const Container = styled('div')`
  width: 64px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  padding-bottom: 32px;
`;

const PlaceholderTop = styled('div')`
  height: 52px;
  width: 100%;
`;

type Props = { navLinks: NavigationLink[] };

const DesktopNavigation = ({ navLinks }: Props) => (
  <Container>
    <div>
      <PlaceholderTop />
      {navLinks.map(({ to, icon, tooltipTitle, roles }) => (
        <NavigationItem to={to} icon={icon} tooltipTitle={tooltipTitle} roles={roles} />
      ))}
    </div>
    <div>
      <NavigationItem
        to="/settings"
        icon={<SettingsIcon />}
        tooltipTitle="Inställningar"
        roles={['admin', 'sales', 'inspector']}
      />
    </div>
  </Container>
);

export default DesktopNavigation;
