import React from 'react';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const StyledCell = styled(TableCell)`
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
`;

const StatusText = styled('p')`
  padding: 2px 5px;
  border-radius: 4px;
  width: fit-content;
  margin: 0;
`;

type Props = { data: any; alignment: any[] };

const Row = ({ data, alignment }: Props) => {
  const navigate = useNavigate();

  const dateFormatter = () => {
    if (data.dateOfInspection) {
      const date = new Date(data.dateOfInspection);
      const pattern = 'dd-MM-yyyy - HH:mm';
      return format(date, pattern);
    }
    return '-';
  };

  const priceFormatter = () => {
    if (data.price) {
      return new Intl.NumberFormat('se-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: 0
      }).format(data.price);
    }
    return '-';
  };

  const statusArr = [
    {
      enName: 'canceled',
      svName: 'avbokad',
      backgroundColor: '#FFE4DC',
      color: '#FC6936'
    },
    {
      enName: 'unprocessed',
      svName: 'obehandlad',
      backgroundColor: '#D4D4D4',
      color: '#7D7D7D'
    },
    {
      enName: 'booked',
      svName: 'bokad',
      backgroundColor: '#E4DAFF',
      color: '#690FDB'
    },
    {
      enName: 'ongoing',
      svName: 'pågående',
      backgroundColor: '#25BBB5',
      color: 'white'
    },
    {
      enName: 'sent',
      svName: 'utskickad',
      backgroundColor: '#DFC351',
      color: 'white'
    },
    {
      enName: 'completed',
      svName: 'klar',
      backgroundColor: '#D3F3DC',
      color: '#279A46'
    }
  ];
  const statusFormatter = () => {
    const matchStatus = statusArr.find((status) => status.enName === data.status);
    return (
      <StatusText
        style={{
          backgroundColor: `${matchStatus?.backgroundColor}`,
          color: `${matchStatus?.color}`
        }}
      >
        {matchStatus?.svName}
      </StatusText>
    );
  };
  return (
    <TableRow hover tabIndex={-1} onClick={() => navigate(data._id)}>
      <StyledCell style={{ fontWeight: 500 }} align={alignment[0]} component="th" scope="row">
        {data.clientName || '-'}
      </StyledCell>
      <StyledCell align={alignment[1]}>{data.objectAddress || '-'}</StyledCell>
      <StyledCell align={alignment[2]}>{data.objectCity || '-'}</StyledCell>
      <StyledCell align={alignment[3]}>{data.inspection || '-'}</StyledCell>
      <StyledCell align={alignment[4]}>{statusFormatter()}</StyledCell>
      <StyledCell align={alignment[5]}>{dateFormatter()}</StyledCell>
      <StyledCell align={alignment[6]}>{priceFormatter()}</StyledCell>
    </TableRow>
  );
};

export default Row;
