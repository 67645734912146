import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import DataGrid from 'components/DataGrid';
import useStore from 'store/useStore';
import StatusBar from 'components/StatusBar';
import ButtonLink from 'components/ButtonLink';
import PlusIcon from 'components/icons/PlusIcon';
import SearchInput from 'components/SearchInput';
import InspectorSelect from 'components/InspectorSelect';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';
import FloatingButton from 'components/FloatingButton';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const TopBar = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  min-height: 46px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    justify-content: space-between;
  }
`;

const SearchFilter = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 12px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    padding: 18px 24px;
  }
`;

const ItemContainer = styled('div')`
  width: 100%;

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 250px;
  }
`;

const Works = () => {
  const headers = [
    {
      name: 'Namn',
      align: 'left'
    },
    {
      name: 'Adress',
      align: 'left'
    },
    {
      name: 'Ort',
      align: 'left'
    },
    {
      name: 'Tjänst',
      align: 'left'
    },
    {
      name: 'Status',
      align: 'left'
    },
    {
      name: 'Datum',
      align: 'left'
    },
    {
      name: 'Avgift',
      align: 'right'
    }
  ];

  const statusBars = [
    {
      label: 'Avbokad',
      value: 'canceled'
    },
    {
      label: 'Obehandlad',
      value: 'unprocessed'
    },
    {
      label: 'Bokad',
      value: 'booked'
    },
    {
      label: 'Pågående',
      value: 'ongoing'
    },
    {
      label: 'Utskickad',
      value: 'sent'
    },
    {
      label: 'Klar',
      value: 'completed'
    }
  ];

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  // Values from store
  const works = useStore((state) => state.works);
  const filter = useStore((state) => state.filter);
  const inspectors = useStore((state) => state.inspectors);
  const isWorksLoading = useStore((state) => state.isWorksLoading);

  // Functions from store
  const setWorks = useStore((state) => state.setWorks);
  const changePage = useStore((state) => state.changePage);
  const changeStatus = useStore((state) => state.changeStatus);
  const changeSearch = useStore((state) => state.changeSearch);
  const setInspectors = useStore((state) => state.setInspectors);
  const resetWorkStore = useStore((state) => state.resetWorkStore);
  const changeInspector = useStore((state) => state.changeInspector);

  useEffect(() => {
    setInspectors();

    return () => {
      // @TODO - Might not be optimal way of reseting store
      resetWorkStore();
    };
  }, []);

  // @TODO - THIS MIGHT NOT BE OPTIMAL
  useEffect(() => {
    setWorks();
  }, [filter]);

  // @TODO: ADD SOME FORM OF DEBOUNCE HERE
  const handlePageChange = (event: any, newPage: number) => {
    changePage(newPage);
  };

  const handleStatusChange = (event: any, status: string) => {
    changeStatus(status);
  };

  const handleInspectorChange = (inspector: any) => {
    changeInspector(inspector);
  };

  // @TODO: ADD SOME FORM OF DEBOUNCE HERE
  const handleSearchChange = (searchString: string) => changeSearch(searchString);

  return (
    <Container>
      <TopBar>
        {filter.search.length < 1 ? (
          <StatusBar
            tabValues={statusBars}
            status={filter.status}
            handleStatusChange={handleStatusChange}
          />
        ) : (
          <div />
        )}

        {lgUp ? (
          <ButtonLink
            to="create"
            buttonText="Skapa arbete"
            endIcon={<PlusIcon stroke="white" width="22px" height="22px" />}
          />
        ) : (
          <FloatingButton to="create" />
        )}
      </TopBar>
      <SearchFilter>
        <ItemContainer style={{ marginBottom: '8px' }}>
          <SearchInput value={filter.search} handleSearchChange={handleSearchChange} />
        </ItemContainer>
        <ItemContainer>
          <InspectorSelect
            value={filter.inspector}
            options={inspectors}
            handleChangeInspector={handleInspectorChange}
          />
        </ItemContainer>
      </SearchFilter>

      <DataGrid
        alignment={['left', 'left', 'left', 'left', 'left', 'left', 'right']}
        rowData={works || []}
        headData={headers}
        loading={isWorksLoading}
        page={filter.page}
        handlePageChange={handlePageChange}
      />
    </Container>
  );
};

export default Works;
