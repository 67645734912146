import React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  width: 100%;
  height: 90vh;
  display: flex;
  background-color: white;
  padding: 16px;
`;

const SiteTitle = styled('div')`
  font-size: 32px;
  font-weight: 500;
`;

const CreateWork = () => (
  <Container>
    <SiteTitle>Create Work</SiteTitle>
  </Container>
);

export default CreateWork;
