import React from 'react';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Head from 'components/DataGrid/Head';
import TableBody from '@mui/material/TableBody';
import Row from 'components/DataGrid/Row';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const Container = styled('div')`
  width: 100%;
  border-radius: 0 0 4px 4px;
  background-color: white;
  padding: 0 12px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0 24px;
  }
`;

const LoadingContainer = styled('div')`
  width: 100%;
  height: 75vh;
  background-color: white;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  headData: any[];
  rowData: any[];
  alignment: string[];
  page: number;
  loading?: boolean;
  handlePageChange: (event: any, newPage: number) => void;
};

const DataGrid = ({ headData, rowData, alignment, loading, page, handlePageChange }: Props) => (
  <Container>
    <TableContainer>
      <Table>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: '#690fdb'
              }}
              size={45}
              thickness={2}
            />
          </LoadingContainer>
        ) : (
          <>
            <Head data={headData} />
            <TableBody>
              {/* @TODO IMPLEMENT DYNAMIC ROW SO THAT IT FITS USERS ASWELL */}
              {/* @TODO CHANGE FROM INDEX TO ID */}
              {rowData?.map((data) => (
                <Row key={data._id} alignment={alignment} data={data} />
              ))}
            </TableBody>
          </>
        )}
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={500}
      rowsPerPage={20}
      page={page}
      onPageChange={handlePageChange}
    />
  </Container>
);

export default DataGrid;
