import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AllowedRole from 'components/AllowedRole';
import { NavigationLink } from 'components/Navigation';

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: #7d7d7d;
`;

const ItemWrapper = styled('div')`
  height: 52px;
  width: 100%;
  padding: 6px 12px;
`;

const Item = styled('div')<StyledProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    color: #690fdb;
    background-color: #ededed;

    & svg {
      stroke: #690fdb;
    }
  }

  & svg {
    stroke: ${(props) => (props.active ? '#690fdb' : '#7d7d7d')};
  }

  background-color: ${(props) => (props.active ? '#ededed' : 'white')};
`;

type StyledProps = {
  active?: boolean;
};

type Props = NavigationLink;

// @TODO: Label should be replaced with icon later on
const NavigationItem = ({ to, icon, tooltipTitle = '', roles }: Props) => (
  <AllowedRole roles={roles}>
    <StyledLink to={to}>
      {({ isActive }) => (
        <ItemWrapper>
          <Tooltip title={tooltipTitle} placement="right">
            <Item active={isActive}>{icon}</Item>
          </Tooltip>
        </ItemWrapper>
      )}
    </StyledLink>
  </AllowedRole>
);

export default NavigationItem;
