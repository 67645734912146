import React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 16px;
`;

const SiteTitle = styled('div')`
  font-size: 32px;
  font-weight: 500;
`;

const NoMatch = () => (
  <Container>
    <SiteTitle>404</SiteTitle>
  </Container>
);

export default NoMatch;
