import Calendar from 'pages/Calendar';
import Home from 'pages/Home';
import Login from 'pages/Login';
import NoMatch from 'pages/NoMatch';
import Settings from 'pages/Settings';
import Statistics from 'pages/Statistics';
import User from 'pages/User';
import Users from 'pages/Users';
import Work from 'pages/Work';
import Works from 'pages/Works';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import useStore from 'store/useStore';
import PrivateRoute from 'components/Routes/PrivateRoute';
import Layout from 'components/Layout';
import CreateWork from 'pages/CreateWork';

const App = () => {
  const getCurrentUser = useStore((state) => state.getCurrentUser);

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <div>
      <Routes>
        {/* @TODO: SEE IF THIS BELOW CAN BE DONE BETTER - LOGIN PAGE SHOULD NOT BE AN AVAILABLE ROUTE WHEN LOGGED IN */}
        <Route path="/login" element={<Login />} />

        <Route element={<Layout />}>
          <Route
            path="/home"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/works"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Works />
              </PrivateRoute>
            }
          />

          <Route
            path="/works/create"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <CreateWork />
              </PrivateRoute>
            }
          />

          <Route
            path="/works/:workId"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Work />
              </PrivateRoute>
            }
          />

          <Route
            path="/statistics"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Statistics />
              </PrivateRoute>
            }
          />

          <Route
            path="/calendar"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Calendar />
              </PrivateRoute>
            }
          />

          <Route
            path="/users"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Users />
              </PrivateRoute>
            }
          />

          <Route
            path="/users/:userId"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <User />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <PrivateRoute roles={['admin', 'sales', 'inspector']}>
                <Settings />
              </PrivateRoute>
            }
          />
        </Route>
        {/* @TODO: LEADS PAGE SHOULD BE HERE ASWELL*/}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

export default App;
