import React from 'react';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

type Props = {
  buttonText: string;
  size?: 'small' | 'medium' | 'large';
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: 'button' | 'submit' | 'reset' | undefined;
  to: string;
  handleClick?: () => void;
};

// @TODO - FIX THE CSS
const StyledButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  padding: '8px 16px',
  fontSize: '14px',
  textDecoration: 'none',
  minHeight: '42px',
  '&:hover': {
    boxShadow: 'none'
  }
}));

const ButtonLink = ({
  buttonText,
  size = 'small',
  startIcon,
  endIcon,
  type,
  to,
  handleClick,
  ...rest
}: Props) => (
  <Link to={to} style={{ textDecoration: 'none' }}>
    <StyledButton
      size={size}
      onClick={handleClick}
      startIcon={startIcon}
      endIcon={endIcon}
      variant="contained"
      disableRipple
      type={type}
      {...rest}
    >
      {buttonText}
    </StyledButton>
  </Link>
);

export default ButtonLink;
