import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const getInspectors = async () => {
  const token = Cookies.get('authToken');

  // Headers
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // If token, add to headers
  if (token) {
    config.headers!['x-auth-token'] = token;
  }
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/role/${'inspector'}`,
    config
  );

  return response.data;
};

export default getInspectors;
