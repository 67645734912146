import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useStore from 'store/useStore';

const ContainerGrid = styled(Grid)``;

const GridItem = styled(Grid)<any>`
  padding-bottom: 16px;
  padding-left: 0px;
  padding-right: 0px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0;
    ${(props) => props.paddingLeft && `padding-left: 16px`};
    ${(props) => props.paddingRight && `padding-right: 16px`};
  }
`;

const Item = styled('div')`
  background-color: #f8f8f8;
  border-radius: 4px;
  min-height: 300px;
  width: 100%;
`;
const Heading = styled('h1')`
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
`;
const Home = () => {
  // @TODO - ADD NAME IN RESPONSE
  const email = useStore((state) => state.user.email);
  const name = email.split('@')[0];
  return (
    <Grid container>
      {/* @TODO - Hej {name}! */}
      <Grid item container xs={12} style={{ marginBottom: '16px' }}>
        <Item style={{ minHeight: '400px', padding: '24px' }}>
          <Heading>{`Hej ${name}!`}</Heading>
        </Item>
      </Grid>
      {/* @TODO - SMÅ SAMPLE CONTAINERS*/}
      <Grid item container xs={12}>
        <GridItem item xs={12} lg={4} paddingRight>
          <Item />
        </GridItem>
        <GridItem item xs={12} lg={4}>
          <Item />
        </GridItem>
        <GridItem item xs={12} lg={4} paddingLeft>
          <Item />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default Home;
