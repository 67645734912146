import React from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';

const Container = styled('div')`
  width: 100%;
  height: 90vh;
  display: flex;
  background-color: white;
  padding: 16px;
`;

const SiteTitle = styled('div')`
  font-size: 32px;
  font-weight: 500;
`;

const Work = () => {
  let params = useParams();

  return (
    <Container>
      <SiteTitle>Arbete:{params.workId}</SiteTitle>
    </Container>
  );
};

export default Work;
