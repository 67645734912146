import React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const Filter = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  height: 70px;
`;

const Main = styled('div')`
  width: 100%;
  background-color: white;
  height: 80vh;
`;

const Calendar = () => (
  <Container>
    <Filter />
    <Main />
  </Container>
);

export default Calendar;
