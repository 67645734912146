import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import useStore from 'store/useStore';
import { NavigationLink } from 'components/Navigation';

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: #7d7d7d;
`;

const ItemWrapper = styled('div')`
  width: 100%;
  padding: 6px 12px;
`;

const Item = styled('div')<StyledProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;

  padding: 12px 16px;
  &:hover {
    color: #690fdb;
    background-color: #ededed;

    & svg {
      stroke: #690fdb;
    }
  }

  & svg {
    stroke: ${(props) => (props.active ? '#690fdb' : '#7d7d7d')};
  }

  color: ${(props) => (props.active ? '#690fdb' : '#7d7d7d')};
  background-color: ${(props) => (props.active ? '#ededed' : 'white')};
`;

const Label = styled('p')`
  font-size: 22px;
  margin: 0;
  margin-left: 12px;
`;

type StyledProps = {
  active?: boolean;
};

type Props = NavigationLink;

const NavigationItem = ({ to, icon, label }: Props) => {
  const toggleDrawer = useStore((state) => state.toggleDrawer);
  return (
    <StyledLink to={to} onClick={toggleDrawer}>
      {({ isActive }) => (
        <ItemWrapper>
          <Item active={isActive}>
            {icon} <Label>{label}</Label>
          </Item>
        </ItemWrapper>
      )}
    </StyledLink>
  );
};

export default NavigationItem;
