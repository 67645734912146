import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface AppSlice {
  isMobileNavigationOpen: boolean;
  toggleDrawer: () => void;
}

const createAppSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  isMobileNavigationOpen: false,
  toggleDrawer: () => {
    set((prev) => ({ isMobileNavigationOpen: !prev.isMobileNavigationOpen }));
  }
});

export default createAppSlice;
