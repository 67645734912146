import React, { forwardRef } from 'react';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { UseFormRegisterReturn } from 'react-hook-form';

// @TODO - FIX THE CSS
const BootstrapInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& label.Mui-focused': {
    borderColor: 'green'
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),

    '&:focus': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'green'
    }
  },
  '& .MuiOutlinedInput-root': {
    // '& fieldset': {
    //   borderColor: 'red'
    // },
    // '&:hover fieldset': {
    //   borderColor: 'yellow'
    // },
    '& input:invalid + fieldset': {
      border: `1px solid red`
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black'
      border: `1px solid ${theme.palette.primary.main}`
    }
  }
}));

const ErrorMessage = styled('p')`
  font-size: 12px;
  color: red;
  font-weight: 400;
  margin: 0;
`;

type Input = {
  label: string;
  defaultValue?: string;
  type?: string;
  error?: boolean;
  register?: () => void;
};

type Props = Input & Partial<UseFormRegisterReturn>;

// The forwardRef and passing of ref and not using it is a fix so that custom Inputs can be used with react-hook-form.
// Read more here -> https://react-hook-form.com/api/useform/register/
const Input = forwardRef(({ label, defaultValue, type = 'text', error, ...props }: Props, ref) => (
  <FormControl variant="standard">
    <InputLabel shrink htmlFor="custom-input">
      {label}
    </InputLabel>
    <BootstrapInput
      inputRef={ref}
      error={error}
      type={type}
      {...props}
      defaultValue={defaultValue}
      id="custom-input"
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </FormControl>
));

export default Input;
