import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const Container = styled(Tabs)`
  &.MuiTabs-root {
    min-height: 46px;
    background-color: #e1e1e1;
    display: flex;
    align-items: center;
    border: none;
    padding: 6px 8px;
    border-radius: 4px;
  }
  position: relative;
`;

const TabItem = styled(Tab)`
  &.MuiTab-root {
    padding: 8px 12px;
    text-transform: capitalize;
    min-height: 30px;
    border-radius: 4px;
    border: none;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }

  &.Mui-selected {
    background-color: white;
    border: none;
  }
`;

type Props = {
  tabValues: any[];
  status: string;
  handleStatusChange: (event: any, status: string) => void;
};

const StatusBar = ({ tabValues, status, handleStatusChange }: Props) => (
  <Container
    value={status}
    onChange={handleStatusChange}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{
      style: {
        display: 'none'
      }
    }}
  >
    {tabValues?.map(({ value, label }) => (
      <TabItem key={value} disableRipple label={label} value={value} />
    ))}
  </Container>
);

export default StatusBar;
