import create from 'zustand';
import { devtools } from 'zustand/middleware';

//Import slices
import createAppSlice, { AppSlice } from 'store/createAppSlice';
import createAuthSlice, { AuthSlice } from 'store/createAuthSlice';
import createWorksSlice, { WorksSlice } from 'store/createWorksSlice';
import createInspectorsSlice, { InspectorsSlice } from 'store/createInspectorsSlice';

export type MyState = AppSlice & AuthSlice & WorksSlice & InspectorsSlice;

const useStore = create<MyState, [['zustand/devtools', never]]>(
  devtools((set, get) => ({
    ...createAppSlice(set, get),
    ...createAuthSlice(set, get),
    ...createWorksSlice(set, get),
    ...createInspectorsSlice(set, get)
  }))
);

export default useStore;
