import axios, { AxiosRequestConfig } from 'axios';

const login = async (email: string, password: string) => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/login`,
    { email, password },
    config
  );

  return res.data;
};

export default login;
