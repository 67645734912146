import React from 'react';
import useStore from 'store/useStore';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
type Props = {
  roles?: string[];
  children: JSX.Element;
};

const PrivateRoute = ({ roles, children }: Props) => {
  let location = useLocation();

  const user = useStore((state) => state.user);
  const isUserLoading = useStore((state) => state.isUserLoading);
  const isAuthenticated = useStore((state) => state.isAuthenticated);

  const isRoleIncluded = roles?.includes(user.role) || false;

  // If not authenticated - Redirect to login
  if (!isAuthenticated && !isUserLoading) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If roles does not have access - Redirect to home
  if (!isRoleIncluded && !isUserLoading) {
    return <Navigate to="*" />;
  }

  // IF A LOADING THINGY IS NEEDED
  // if (!isUserLoading) {
  //   // ADD A LOADING PAGE HERE
  //   return <div>PAGE</div>;
  // }

  return children;
};

export default PrivateRoute;
