import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useStore from 'store/useStore';
import UserIcon from 'components/icons/UserIcon';
import MenuIcon from 'components/icons/MenuIcon';
import BellIcon from 'components/icons/BellIcon';

const Container = styled('div')`
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 8px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 8px 32px;
    justify-content: flex-end;
  }
`;

const Button = styled('button')`
  background-color: #ededed;
  border-radius: 4px;
  color: #7d7d7d;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
    opacity: 0.75;
    color: #690fdb;

    & svg {
      stroke: #690fdb;
    }
  }
`;

const Header = () => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const toggleDrawer = useStore((state) => state.toggleDrawer);

  return (
    <Container>
      {!lgUp && (
        <Button style={{ backgroundColor: 'white' }} onClick={toggleDrawer}>
          <MenuIcon />
        </Button>
      )}

      <div style={{ display: 'flex' }}>
        {/* @TODO: CREATE COMPONENT FOR POPOVER - PROFILE AND NOTIS */}
        <Button>
          <BellIcon width="22px" height="22px" stroke="#7D7D7D" />
        </Button>
        <Button style={{ marginLeft: '8px' }}>
          <UserIcon width="22px" height="22px" stroke="#7D7D7D" />
        </Button>
      </div>
    </Container>
  );
};

export default Header;
