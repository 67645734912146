import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import useStore from 'store/useStore';
import PageLoading from 'components/PageLoading';

import Header from 'components/Header';
import Navigation from 'components/Navigation';

const Container = styled('div')`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Content = styled('div')`
  width: 100%;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: calc(100% - 64px);
  }
`;

const PageWrapper = styled('div')`
  padding: 24px 8px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 24px 32px;
  }
`;

const Layout = () => {
  const isUserLoading = useStore((state) => state.isUserLoading);
  return (
    <Fragment>
      {isUserLoading ? (
        <PageLoading />
      ) : (
        <Fragment>
          <Container>
            <Navigation />
            <Content>
              <Header />
              <PageWrapper>
                <Outlet />
              </PageWrapper>
            </Content>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Layout;
