import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      prop: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      prop?: string;
    };
  }
}

// Material UI Theme - Resource: https://mui.com/customization/theming/
export const theme = {
  palette: {
    common: {
      white: '#fff',
      black: '#000'
    },
    primary: {
      main: '#690fdb'
    },
    secondary: {
      main: '#607D8B'
    },
    background: {
      default: '#F5F8F9',
      paper: '#fff'
    },
    error: {
      main: '#EA544A'
    },
    text: {
      primary: '#262626',
      secondary: '#808080'
    }
  },
  typography: {
    fontFamily: 'Montserrat'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#EDEDED'
        }
      }
    }
  }
};

export default createTheme(theme);
