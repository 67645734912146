import React, { useEffect } from 'react';
import useStore from 'store/useStore';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Input from 'components/Input';
import Button from 'components/ButtonLoading';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Container = styled('div')`
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
`;

const LoginContainer = styled('div')`
  width: 100%;
  max-width: 500px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 16px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0;
  }
},
`;

const Heading = styled('h1')`
  font-size: 32px;
  color: #000;
  font-weight: 500;
  margin: 0;
`;

const Subheading = styled('h2')`
  font-size: 16px;
  color: #7d7d7d;
  font-weight: 400;
  margin: 0;
  padding-bottom: 24px;

  a {
    color: #4a158d;
    text-decoration: none;
  }
`;

const InputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const login = useStore((state) => state.login);
  const isUserLoading = useStore((state) => state.isUserLoading);
  const isAuthenticated = useStore((state) => state.isAuthenticated);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    return () => console.log('unmounted');
  }, []);

  // Bug in react-hook-form, can't type the data from onSubmit without error
  const onSubmit = ({ email, password }: any) => {
    return login(email, password);
  };

  useEffect(() => {
    if (isAuthenticated && !isUserLoading) {
      navigate('/home', { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <Container>
      <Grid container sx={{ flexGrow: 1, height: '100%' }}>
        <Grid
          item
          container
          xs={12}
          lg={6}
          style={{ backgroundColor: 'white' }}
          justifyContent="center"
        >
          {/* --- LOGIN FORM COMPONENT STARTS HERE --- */}
          <LoginContainer>
            <Heading>Logga in</Heading>
            <Subheading>
              Har du inte ett konto? <a href="mailto: oskar@eveture.com">Kontakta oss.</a>
            </Subheading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <Input
                  {...register('email', {
                    required: 'Vänligen ange en korrekt email',
                    minLength: 1,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Vänligen ange en korrekt email'
                    }
                  })}
                  error={errors.email && true}
                  label="Email"
                />
              </InputContainer>
              <InputContainer>
                <Input
                  {...register('password', {
                    required: 'Vänligen ange ett lösenord',
                    minLength: 1
                  })}
                  type="password"
                  label="Lösenord"
                  error={errors.password && true}
                />
              </InputContainer>
              <Button type="submit" size="large" buttonText="Logga in" loading={isUserLoading} />
            </form>
          </LoginContainer>
          {/* --- LOGIN FORM COMPONENT ENDS HERE --- */}
        </Grid>
        {/* --- MEDIA DIV COMPONENT STARTS HERE --- */}
        {lgUp && <Grid item container lg={6} style={{ backgroundColor: 'transparent' }} />}
        {/* --- MEDIA DIV COMPONENT ENDS HERE --- */}
      </Grid>
    </Container>
  );
};

export default Login;
