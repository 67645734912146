import React from 'react';

type Props = {
  stroke?: string;
  height?: string;
  width?: string;
};

const CrossIcon = ({ stroke = 'black', height = '24px', width = '24px' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke}
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

export default CrossIcon;
