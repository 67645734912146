import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import DataGrid from 'components/DataGrid';
import useStore from 'store/useStore';
import StatusBar from 'components/StatusBar';
import ButtonLink from 'components/ButtonLink';
import PlusIcon from 'components/icons/PlusIcon';
import SearchInput from 'components/SearchInput';
import InspectorSelect from 'components/InspectorSelect';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';
import FloatingButton from 'components/FloatingButton';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const TopBar = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  min-height: 46px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    justify-content: space-between;
  }
`;

const SearchFilter = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 12px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    padding: 18px 24px;
  }
`;

const ItemContainer = styled('div')`
  width: 100%;

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 250px;
  }
`;

const Users = () => {
  const headers = [
    {
      name: 'Namn',
      align: 'left'
    },
    {
      name: 'Telefon',
      align: 'left'
    },
    {
      name: 'Mail',
      align: 'left'
    },
    {
      name: 'Ort',
      align: 'left'
    }
  ];

  const statusBars = [
    {
      label: 'Alla',
      value: 'all'
    },
    {
      label: 'Intern',
      value: 'internal'
    },
    {
      label: 'Extern',
      value: 'external'
    }
  ];

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  // Values from store
  const works = useStore((state) => state.works);
  const filter = useStore((state) => state.filter);
  // const inspectors = useStore((state) => state.inspectors);
  // const isWorksLoading = useStore((state) => state.isWorksLoading);

  // Functions from store
  // const setWorks = useStore((state) => state.setWorks);
  // const changePage = useStore((state) => state.changePage);
  // const changeStatus = useStore((state) => state.changeStatus);
  // const changeSearch = useStore((state) => state.changeSearch);
  // const setInspectors = useStore((state) => state.setInspectors);
  // const resetWorkStore = useStore((state) => state.resetWorkStore);
  // const changeInspector = useStore((state) => state.changeInspector);

  // useEffect(() => {
  //   setInspectors();

  //   return () => {
  //     // @TODO - Might not be optimal way of reseting store
  //     resetWorkStore();
  //   };
  // }, []);

  {
    /* @TODO: ADD CORRECT VALUES FOR THE FUNCTIONS BELOW*/
  }

  // @TODO: ADD SOME FORM OF DEBOUNCE HERE
  const handlePageChange = (event: any, newPage: number) => {
    // changePage(newPage);
    console.log(newPage);
  };

  const handleStatusChange = (event: any, status: string) => {
    // changeStatus(status);
    console.log(status);
  };

  const handleInspectorChange = (inspector: any) => {
    // changeInspector(inspector);
    console.log(inspector);
  };

  // @TODO: ADD SOME FORM OF DEBOUNCE HERE
  // const handleSearchChange = (searchString: string) => changeSearch(searchString);
  const handleSearchChange = (searchString: string) => console.log(searchString);

  return (
    <Container>
      <TopBar>
        {/*@TODO: ADD CORRECT VALUE SOURCE HERE*/}
        {/* {filter.search.length < 1 ? ( */}
        <StatusBar
          tabValues={statusBars}
          // @TODO: ADD CORRECT VALUE SOURCE HERE
          status={'all'}
          handleStatusChange={handleStatusChange}
        />
        {/* ) : (
      <div />
        )} */}

        {lgUp ? (
          <ButtonLink
            to="create"
            buttonText="Skapa användare"
            endIcon={<PlusIcon stroke="white" width="22px" height="22px" />}
          />
        ) : (
          <FloatingButton to="create" />
        )}
      </TopBar>
      <SearchFilter>
        <ItemContainer style={{ marginBottom: '8px' }}>
          {/* @TODO: ADD CORRECT VALUE SOURCE HERE*/}
          <SearchInput value={''} handleSearchChange={handleSearchChange} />
        </ItemContainer>
      </SearchFilter>
      {/* @TODO: RE-MAKE dataGrid so that rows are more dynamic */}
      <DataGrid
        alignment={['left', 'left', 'left', 'left']}
        // rowData={works || []}
        rowData={[]}
        headData={headers}
        // loading={isWorksLoading}
        loading={false}
        // page={filter.page}
        page={0}
        handlePageChange={handlePageChange}
      />
    </Container>
  );
};

export default Users;
